export const ASSESSMENT_STATUS = {
  GENERATED: 'Generated',
  UPLOADED: 'Uploaded',
  DRAFT: 'Draft',
  GENERATING: 'Generating'
};

export const DIFFICULTY_LEVEL = {
  EASY: 'Easy',
  MEDIUM: 'Medium',
  DIFFICULT: 'Difficult'
};
