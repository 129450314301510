import styled from 'styled-components';
import { siteColors } from '../../../../themes/colors';
import fonts from '../../../../themes/fonts';
import { RulesInfoProps } from '../../types';

const Container = styled.div`
  background: ${siteColors.darkBlue};
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin-bottom: 1.5rem;
`;

const RuleHeading = styled.p`
  color: ${siteColors.inActiveColor};
  ${fonts.size.regular()};
  ${fonts.weights.fw600()};
  line-height: 17px;
`;

const RuleInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const SubHeading = styled.p`
  color: ${siteColors.inActiveColor};
  ${fonts.size.small()};
  ${fonts.weights.fw400()};
  line-height: 15.23px;
`;

const InfoText = styled.p`
  color: ${siteColors.nuetral};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  line-height: 1rem;
`;

const RuleInfo = ({ ruleData, index }: RulesInfoProps) => {
  return (
    <Container>
      <RuleHeading>{`Rule #${index + 1}`}</RuleHeading>
      <RuleInfoContainer>
        <SubHeading>Topic</SubHeading>
        <InfoText>{ruleData?.topic_name}</InfoText>
      </RuleInfoContainer>
      <RuleInfoContainer>
        <SubHeading>Subtopic</SubHeading>

        <InfoText>{ruleData?.subtopic_name}</InfoText>
      </RuleInfoContainer>
      <RuleInfoContainer>
        <SubHeading>Number of questions</SubHeading>
        <InfoText>{ruleData?.num_of_questions}</InfoText>
      </RuleInfoContainer>
      <RuleInfoContainer>
        <SubHeading>Kind</SubHeading>
        <InfoText>{ruleData?.difficult_level}</InfoText>
      </RuleInfoContainer>
    </Container>
  );
};

export default RuleInfo;
