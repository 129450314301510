import { callAxiosGet } from '../../../utils/axiosUtil';

export const GetQuestions = async (assessmentId: number, limit: number, page: number) => {
  const response = await callAxiosGet(
    `${process.env.REACT_APP_BACKEND_API}/questions/${assessmentId}/?limit=${limit}&page=${page}`
  );

  return response.data;
};

export const GetAssessmentDetails = async (assessmentId: number) => {
  const response = await callAxiosGet(`${process.env.REACT_APP_BACKEND_API}/assessment/${assessmentId}/`);
  return response.data;
};
