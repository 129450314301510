import styled from 'styled-components';
import { siteColors } from '../../../../themes/colors';
import fonts from '../../../../themes/fonts';
import { ASSESSMENT_STATUS } from '../../../../utils/constants';
import { StatusTagProps, TagProps } from '../../types';

const TagContainer = styled.div<TagProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0.625rem;
  gap: 0.5rem;
  background: ${(props) => {
    return props.tagBg;
  }};
  border: 0.6px solid ${(props) => props.tagBorder};
  width: fit-content;

  border-radius: 999px;
`;

const TagText = styled.p<TagProps>`
  color: ${(props) => props.tagColor};
  ${fonts.size.small()};
  ${fonts.weights.fw400()};
  line-height: 15.23px;
`;

const TagStatusCircle = styled.div<TagProps>`
  border-radius: 100%;
  background: ${(props) => props.tagColor};
  height: 0.625rem;
  width: 0.625rem;
`;

const StatusTags = ({ status }: StatusTagProps) => {
  let tagColor;
  let tagBg;
  let tagBorder;
  switch (status) {
    case ASSESSMENT_STATUS.GENERATED:
      tagColor = `${siteColors.tagTextGen}`;
      tagBg = `${siteColors.tagBgGen}`;
      tagBorder = `${siteColors.tagBorderGen}`;
      break;
    case ASSESSMENT_STATUS.DRAFT:
      tagColor = `${siteColors.tagTextDrafts}`;
      tagBg = `${siteColors.tagBgDrafts}`;
      tagBorder = `${siteColors.tagBorderDrafts}`;
      break;
    case ASSESSMENT_STATUS.UPLOADED:
      tagColor = `${siteColors.tagTextUploaded}`;
      tagBg = `${siteColors.tagBgUploaded}`;
      tagBorder = `${siteColors.tagBorderUploaded}`;
      break;
    default:
      tagColor = `${siteColors.tagTextGen}`;
      tagBg = `${siteColors.tagBgGen}`;
      tagBorder = `${siteColors.tagBorderGen}`;
  }
  return (
    <TagContainer tagColor={tagColor} tagBg={tagBg} tagBorder={tagBorder}>
      <TagStatusCircle tagColor={tagColor} />
      <TagText tagColor={tagColor}>{status}</TagText>
    </TagContainer>
  );
};

export default StatusTags;
