import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import CommonNavbar from '../../components/CommonNavbar';
import { siteColors } from '../../themes/colors';
import fonts from '../../themes/fonts';
import {
  approveQuestions,
  assessmentStatusChange,
  GetGeneratedQuestions,
  regenerateQuestions,
  uploadToCms
} from './api';
import QuestionCard from './components/QuestionCard';
import ReviewActionComponent from './components/ReviewActionComponent';
import SideBar from './components/SideBar';
import { useQuery, useMutation } from 'react-query';
import { ASSESSMENT_STATUS } from '../../utils/constants';
import { useToast } from '@chakra-ui/react';
import SuccessIcon from '../../images/icons/successIcon.svg';
import { useAuth } from '../../auth/auth';

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  overflow-y: hidden;
`;

const AssessmentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2.5rem;
  gap: 1.5rem;
  overflow-y: auto;
`;

const QuestionsHeading = styled.p`
  color: ${siteColors.black};
  ${fonts.size.xBig()};
  ${fonts.weights.fw400()};
  line-height: 1.63rem;
`;

const CustomNotification = styled.div`
  width: 23.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  gap: 8px;
  background: ${siteColors.notificationBg};
  box-shadow: 0px 1px 11px ${siteColors.notificationBoxShadow};
  border-radius: 8px;
`;

const StyledImg = styled.img``;

const NotificationText = styled.p`
  color: ${siteColors.tagTextUploaded};
  ${fonts.size.regular()};
  ${fonts.weights.fw600()};
  line-height: 17px;
`;

const ReviewAssessment = () => {
  const { assessmentId = '', numQues = 0 } = useParams();
  const [approveCounter, setApproveCounter] = useState(0);
  const [searchParams] = useSearchParams();
  const generatedParam = searchParams.get('generated');

  const [renderedData, setRenderedData] = useState<any[]>([]);

  const { data, refetch } = useQuery('viewQuestionsForReview', () =>
    GetGeneratedQuestions(assessmentId, Number(numQues), 1)
  );

  console.log('HERE', data);
  const context = useAuth();

  useEffect(() => {
    const quesData = data?.questions;

    let timeoutId: any;
    if (quesData) {
      if (generatedParam) {
        const delay = 3000; // Set the delay between rendering each component (in milliseconds)

        if (renderedData?.length === 0) {
          setRenderedData((prevRenderedData: any) => [quesData[0]]);
        } else if (renderedData?.length < quesData?.length) {
          timeoutId = setTimeout(() => {
            const nextRenderedData = quesData[renderedData?.length];
            setRenderedData((prevRenderedData: any) => [...prevRenderedData, nextRenderedData]);
          }, delay);
        } else if (JSON.stringify(renderedData) !== JSON.stringify(quesData)) {
          setRenderedData(quesData);
        } else {
          clearTimeout(timeoutId);
        }
      } else {
        setRenderedData(quesData);
      }
    }

    return () => clearTimeout(timeoutId); // Clean up the timeout on component unmount
  }, [renderedData, data]);

  const navigate = useNavigate();

  const mutateRegenQuestions = useMutation(regenerateQuestions, {
    onSuccess: (response) => {
      const regenQuestion = response.data[0];
      const index = response.index;
      data.data.questions.questions[index] = regenQuestion;
      setRenderedData(data.data.questions.questions);
    },
    onError: (error: any) => {
      alert('there was an error');
      console.log('error', error);
    }
  });

  const mutateApproveQuestions = useMutation(approveQuestions, {
    onSuccess: (data) => {
      refetch();
    },
    onError: (error: any) => {
      alert('Cannot approve');
      console.log('error', error);
    }
  });

  const mutateAssessmentStatus = useMutation(assessmentStatusChange, {
    onSuccess: (data) => {
      navigate('/?next=true');
    },
    onError: (error: any) => {
      alert('Cannot update status');
      console.log('error', error);
    }
  });

  const mutateUploadToCMS = useMutation(uploadToCms, {
    onSuccess: (data) => {
      const newTabUrl = `/student-view?id=${assessmentId}`;
      // openInNewTab(newTabUrl);
      navigate('/?next=true');
      toast({
        duration: 9000,
        render: () => (
          <CustomNotification>
            <StyledImg src={SuccessIcon} />
            <NotificationText>
              <FormattedMessage id="toast_title" />
            </NotificationText>
          </CustomNotification>
        )
      });
    },
    onError: (error: any) => {
      alert('Cannot upload doc');
      console.log('error', error);
    }
  });

  useEffect(() => {
    if (data) {
      const totalApproved = data?.questions?.reduce((total: number, currQues: any) => {
        return total + (currQues?.status === 'approved' ? 1 : 0);
      }, 0);

      setApproveCounter(totalApproved);
    }
  }, [data]);

  const handleapprove = (quesData: any, index: number) => {
    const approvePayload = {
      valid: 1
    };
    mutateApproveQuestions.mutate({
      index,
      questionId: quesData?.question_id,
      data: approvePayload
    });
  };

  const handleRegenerateQues = (quesData: any, index: number) => {
    const regenerateInput = {
      topic_id: parseInt(quesData?.topic_id),
      subtopic_id: parseInt(quesData?.subtopic_id),
      difficulty_level: quesData?.difficulty_level,
      assessment_id: quesData?.assessment_id
    };
    const quesId = quesData?.question_id;

    mutateRegenQuestions.mutate({
      index,
      questionId: quesId,
      data: regenerateInput
    });
  };

  const handleStatusChange = () => {
    const statusPayload = {
      status: ASSESSMENT_STATUS.UPLOADED
    };
    mutateAssessmentStatus.mutate({
      assessmentId: assessmentId,
      data: statusPayload
    });
  };

  const handledocUpload = () => {
    let tenantKey = 'YOJITO';
    if (context?.user_profile?.workspace?.key) {
      tenantKey = context?.user_profile?.workspace?.key;
    }
    mutateUploadToCMS.mutate({
      docId: assessmentId,
      tenantKey
    });
  };

  const openInNewTab = (url: string) => {
    const newTab = window.open(url, '_blank');

    if (newTab) {
      newTab.addEventListener('load', () => {});
    }
  };

  const toast = useToast({
    position: 'top-right'
  });

  return (
    <Container>
      <CommonNavbar id={`${assessmentId}`} isGenerated={!!generatedParam} />
      <ContentContainer>
        <SideBar
          totalQues={data?.total_questions}
          rulesData={data?.data?.rules}
          assessmentData={data?.data?.assessment}
        />
        <AssessmentContainer>
          <QuestionsHeading>
            <FormattedMessage id="ques_generated" />
          </QuestionsHeading>
          {renderedData?.map((ques: any, index: any) => (
            <QuestionCard
              key={JSON.stringify(ques)}
              count={index}
              quesData={ques}
              handleapprove={handleapprove}
              handleQuesRegenration={handleRegenerateQues}
              shouldType={!!generatedParam && !ques?.valid}
            />
          ))}
          <ReviewActionComponent
            assessmentId={Number(assessmentId)}
            canUpload={approveCounter === data?.total_questions}
            handleStatusChange={handleStatusChange}
            handledocUpload={handledocUpload}
            uplodaLoading={mutateUploadToCMS.isLoading}
            documentStatus={data?.document_details?.document_status}
          />
        </AssessmentContainer>
      </ContentContainer>
    </Container>
  );
};

export default ReviewAssessment;
