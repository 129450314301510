import { Input } from '@chakra-ui/react';
import styled from 'styled-components';
import { siteColors } from '../../themes/colors';
import fonts from '../../themes/fonts';
import leftArrow from '../../images/icons/leftArrow.svg';
import rightArrow from '../../images/icons/rightArrow.svg';
import If from '../If';

type PaginationProps = {
  currentPage: number;

  startIdx: number;
  endIdx: number;
  totalCount: number;
  rowValue: any;

  onPageChange: (n: number) => void;
  handleRowChange: (e: any) => void;
};

const Paginationcontainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 1.75rem;
  padding: 0px 1rem;
`;

const ShowPageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const PaginationText = styled.p`
  ${fonts.size.xsmall()};
  ${fonts.weights.fw400()};
  line-height: 0.81rem;
  color: ${siteColors.textGrey};
`;

const CustomInput = styled(Input)`
  && {
    width: 61px;
    border-radius: 4px;
    border: 1px solid ${siteColors.lightBlue};
    padding: 4px 12px;
    height: 22px;

    ${fonts.size.xsmall()};
    ${fonts.weights.fw400()};
    line-height: 0.81rem;

    &:focus {
      outline: none;
      box-shadow: none;
      border-color: ${siteColors.lightBlue};
    }
    &:hover {
      outline: none;
      border-color: ${siteColors.lightBlue};
    }
  }
`;

const StyledImg = styled.img`
  cursor: pointer;
  width: 5px;
  height: 8px;
`;

export default function Pagination({
  currentPage,
  startIdx,
  endIdx,
  totalCount,
  rowValue,
  onPageChange,
  handleRowChange
}: PaginationProps) {
  const handlePrevPage = () => {
    onPageChange(currentPage - 1);
  };

  const handleNextPage = () => {
    onPageChange(currentPage + 1);
  };

  const endText = Number(endIdx) > totalCount ? totalCount : Number(endIdx);

  return (
    <Paginationcontainer>
      <ShowPageContainer>
        <PaginationText>Show rows per page</PaginationText>
        <CustomInput type="number" value={rowValue} onChange={(e: any) => handleRowChange(e)} />
      </ShowPageContainer>
      <ShowPageContainer>
        <PaginationText>{`${Number(startIdx + 1)}-${endText} of ${totalCount}`}</PaginationText>
        <If condition={startIdx > 0}>
          <StyledImg src={leftArrow} onClick={handlePrevPage} />
        </If>
        <If condition={endIdx < totalCount}>
          <StyledImg src={rightArrow} onClick={handleNextPage} />
        </If>
      </ShowPageContainer>
    </Paginationcontainer>
  );
}
