import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import CommonNavbar from '../../components/CommonNavbar';
import { siteColors } from '../../themes/colors';
import fonts from '../../themes/fonts';
import QuestionCard from './components/QuestionCard';
import SideBar from './components/SideBar';
import { useQuery } from 'react-query';
import { GetQuestions } from './api';
import If from '../../components/If';
import { ASSESSMENT_STATUS } from '../../utils/constants';
import GearGIf from '../../images/aiAtWorkGif.gif';

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  overflow-y: hidden;
`;

const AssessmentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2.5rem;
  gap: 1.5rem;
  overflow-y: auto;
`;

const QuestionsHeading = styled.p`
  color: ${siteColors.black};
  ${fonts.size.xBig()};
  ${fonts.weights.fw400()};
  line-height: 1.63rem;
`;

const SubContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FillDetails = styled.p`
  color: ${siteColors.black};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  line-height: 1.0625rem;
`;

// const FillDetails = styled.p`
//   color: ${siteColors.black};
//   ${fonts.size.xBig()};
//   ${fonts.weights.fw600()};
//   line-height: 1.0625;
// `;

const StyledImg = styled.img`
  width: 325px;
  height: 325px;
`;

const ViewAssessment = () => {
  const { assessmentId } = useParams();

  const { data } = useQuery('viewQuestions', () => GetQuestions(Number(assessmentId), 0, 1));

  // const assessmentDetailsQuery = useQuery(
  //   ["assessment_details", assessmentId],
  //   () => GetAssessmentDetails(Number(assessmentId))
  // );

  const assessmentStatus = data?.data?.assessment?.assessment_status;

  return (
    <Container>
      <CommonNavbar id={`${assessmentId}`} />
      <ContentContainer>
        <SideBar
          totalQues={data?.data?.assessment?.count}
          rulesData={data?.data?.rules}
          assessmentData={data?.data?.assessment}
        />
        <AssessmentContainer>
          <If condition={assessmentStatus !== ASSESSMENT_STATUS.GENERATING}>
            <QuestionsHeading>Questions</QuestionsHeading>
            {data?.data?.questions?.questions?.map((ques: any, index: number) => (
              <QuestionCard key={index} count={index} ques={ques} />
            ))}
          </If>
          <If condition={assessmentStatus === ASSESSMENT_STATUS.GENERATING}>
            <SubContentContainer>
              <StyledImg src={GearGIf} alt="GIF" />
              <FillDetails>AI at work...</FillDetails>
            </SubContentContainer>
          </If>
        </AssessmentContainer>
      </ContentContainer>
    </Container>
  );
};

export default ViewAssessment;
