import { Button, useToast } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { siteColors } from '../../../../themes/colors';
import fonts from '../../../../themes/fonts';
import { ActionButtonProps, ReviewActionComponentProps } from '../../types';
import { ASSESSMENT_STATUS } from '../../../../utils/constants';

const Container = styled.div`
  flex-grow: 0;
  position: sticky;
  bottom: 0.5rem;
  left: 50%;
  transform: translateX(-20%);
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 2.5rem;
  gap: 2.5rem;
  background: ${siteColors.nuetral};

  border-radius: 0.5rem;
  box-shadow: 0px 4px 17px ${siteColors.actionBoxShadow};
`;

const CustomButton = styled(Button)<ActionButtonProps>`
  && {
    height: 3.5rem;
    padding: 12px 24px;
    gap: 8px;
    border-radius: 4px;
    background: ${(props) => props.bgColor};
    border: none;

    color: ${siteColors.nuetral};
    ${fonts.size.regular()};
    ${fonts.weights.fw600()};
    line-height: 17px;

    &:hover {
      background-color: ${(props) => props.bgColor};
    }
  }
`;

const ReviewActionComponent = ({
  assessmentId,
  canUpload,
  uplodaLoading,
  documentStatus,
  handleStatusChange,
  handledocUpload
}: ReviewActionComponentProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    const newTabUrl = `/student-view?id=${assessmentId}`;
    // handleStatusChange();
    handledocUpload();

    // openInNewTab(newTabUrl);
  };

  const toast = useToast({
    position: 'top-right'
  });

  return (
    <Container>
      <CustomButton bgColor={`${siteColors.tagTextGen}`}>
        <FormattedMessage id="regen_assessment" />
      </CustomButton>
      <CustomButton bgColor={`${siteColors.grey}`}>
        <FormattedMessage id="save_drafts" />
      </CustomButton>
      <CustomButton
        bgColor={`${siteColors.tagTextUploaded}`}
        onClick={handleClick}
        isDisabled={
          documentStatus?.toLocaleLowerCase() === ASSESSMENT_STATUS?.UPLOADED?.toLocaleLowerCase() || !canUpload
        }
        isLoading={uplodaLoading}
      >
        <FormattedMessage
          id={
            documentStatus?.toLocaleLowerCase() === ASSESSMENT_STATUS?.UPLOADED?.toLocaleLowerCase()
              ? 'uploaded_lms'
              : 'upload_lms'
          }
        />
      </CustomButton>
    </Container>
  );
};

export default ReviewActionComponent;
