import styled from 'styled-components';
import { siteColors } from '../../themes/colors';
import Back from '../../images/icons/backArrow.svg';
import fonts from '../../themes/fonts';
import { CommonNavbarProps } from './types';
import ProfilePic from '../../images/icons/defaultProfile.svg';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const NavbarContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${siteColors.primary};
  height: 4rem;
  padding: 20px 28px;
`;

const InfoContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const StyledImg = styled.img`
  cursor: pointer;
`;

const HeaderText = styled.p`
  color: ${siteColors.nuetral};
  ${fonts.weights.medium()};
  ${fonts.size.small()};
  line-height: 15.23px;
`;

const ProfileImg = styled.img`
  border-radius: 100%;
`;

const CommonNavbar = ({ id, isGenerated }: CommonNavbarProps) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (isGenerated) {
      navigate('/?next=true');
    } else {
      navigate(-1);
    }
  };
  return (
    <NavbarContainer>
      <InfoContainer>
        <StyledImg src={Back} onClick={handleNavigate} />
        {id ? (
          <HeaderText>{`Assessment ID ${id}`}</HeaderText>
        ) : (
          <HeaderText>
            <FormattedMessage id={'generate_assessment'} />
          </HeaderText>
        )}
      </InfoContainer>
      <ProfileImg src={ProfilePic} />
    </NavbarContainer>
  );
};

export default CommonNavbar;
