import { FormControl, FormLabel, Input, Radio, RadioGroup, Select, Stack } from '@chakra-ui/react';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { siteColors } from '../../../../themes/colors';
import fonts from '../../../../themes/fonts';
import { DetailsFormProps } from '../../types';
import { DIFFICULTY_LEVEL } from '../../../../utils/constants';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Heading = styled.p`
  color: ${siteColors.nuetral};
  ${fonts.size.xBig()};
  ${fonts.weights.fw400()};
  line-height: 1.63rem;
  margin-bottom: 1.5rem;
`;

const CustomFormLabel = styled(FormLabel)`
  && {
    color: ${siteColors.white};
    ${fonts.size.small()};
    ${fonts.weights.fw400()};
    line-height: 0.951rem;
    margin-bottom: 0.5rem;
  }
`;

const CustomInput = styled(Input)`
  && {
    height: 3.312rem;
    background: ${siteColors.primary};
    padding: 1.125rem 1rem;
    border-radius: 0.5rem;
    color: ${siteColors.nuetral};
    border: 1px solid ${siteColors.inputBorderColor};
    margin-bottom: 1rem;

    &::placeholder {
      ${fonts.weights.fw400()};
      ${fonts.size.regular()};
      line-height: 17.41px;
      color: ${siteColors.inActiveColor};
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:hover {
      outline: none;
      border-color: ${siteColors.inputBorderColor};
    }
  }
`;

const CustomFormLabelRadio = styled(FormLabel)`
  && {
    color: ${siteColors.white};
    ${fonts.size.regular()};
    ${fonts.weights.fw600()};
    line-height: 1.08rem;
    margin-bottom: 0.75rem;
  }
`;

const CustomRadio = styled(Radio)`
  .chakra-radio__control {
    border-color: red;
  }

  .chakra-radio__label {
  }
`;

const CustomStack = styled(Stack)`
  && {
    > * {
      color: ${siteColors.nuetral};
      ${fonts.size.regular()};
      ${fonts.weights.fw400()};
      line-height: 1.08rem;

      :nth-child(2) {
        height: 1.25rem;
        width: 1.25rem;
        border: 1px solid ${siteColors.inputBorderColor};
        background: none;
        color: ${siteColors.tagTextUploaded};
      }
    }
  }
`;

const CustomSelect = styled(Select)`
  &&& {
    &:nth-child(1) {
      height: 3.312rem;
      background: ${siteColors.primary};
      border-radius: 0.5rem;
      color: ${siteColors.inActiveColor};
      border: 1px solid ${siteColors.inputBorderColor};
      margin-bottom: 1rem;

      &::placeholder {
        ${fonts.weights.fw400()};
        ${fonts.size.regular()};
        line-height: 17.41px;
        color: ${siteColors.inActiveColor};
      }
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:hover {
      outline: none;
      border-color: ${siteColors.inputBorderColor};
    }
  }
`;

const DetailsForm = ({
  subjectOptions,
  formData,
  courseOptions,
  topicOptions,
  subTopicOptions,
  handleChange,
  status
}: DetailsFormProps) => {
  const [value, setValue] = useState('1');

  return (
    <FormContainer>
      <Heading>
        <FormattedMessage id="fill_details" />
      </Heading>
      <FormControl>
        <CustomFormLabel>
          <FormattedMessage id="sub_name" />
        </CustomFormLabel>
        <CustomSelect
          disabled={!!status}
          name="subjectName"
          onChange={handleChange}
          value={formData.subjectName}
          disablePlaceholderValue
          placeholder="Select Subject"
        >
          {subjectOptions.map((option: any, index: number) => (
            <option key={index} value={option?.value}>
              {option.label}
            </option>
          ))}
        </CustomSelect>
      </FormControl>

      {/* Topic Selector */}
      <FormControl>
        <CustomFormLabel>
          <FormattedMessage id="topic" />
        </CustomFormLabel>
        <CustomSelect
          disabled={!!status}
          name="topic"
          onChange={handleChange}
          value={formData.topic}
          disablePlaceholderValue
          placeholder="Select Topic"
        >
          {topicOptions.map((option: any) => (
            <option key={option?.value} value={[option?.value, option?.label]}>
              {option.label}
            </option>
          ))}
        </CustomSelect>
      </FormControl>

      {/* SubTopic Selector */}
      <FormControl>
        <CustomFormLabel>
          <FormattedMessage id="sub_topic" />
        </CustomFormLabel>
        <CustomSelect
          disabled={!!status}
          name="subTopic"
          onChange={handleChange}
          value={formData.subTopic}
          disablePlaceholderValue
          placeholder="Select SubTopic"
        >
          {subTopicOptions.map((option: any) => (
            <option key={option?.value} value={option?.label}>
              {option.label}
            </option>
          ))}
        </CustomSelect>
      </FormControl>

      <FormControl>
        <CustomFormLabel>
          <FormattedMessage id="total_no_ques" />
        </CustomFormLabel>
        <CustomInput
          disabled={!!status}
          placeholder={'eg: 10'}
          name="totalQues"
          value={formData.totalQues}
          initialValue={formData.totalQues}
          onChange={handleChange}
          type="number"
        />
      </FormControl>

      {/* <FormControl>
        <CustomFormLabel>
          <FormattedMessage id="course_name" />
        </CustomFormLabel>
        <CustomInput
          disabled={!!status}
          placeholder={'eg: MATH102_V8'}
          name="courseName"
          value={formData.courseName}
          onChange={handleChange}
        />
      </FormControl> */}

      <FormControl>
        <CustomFormLabelRadio>
          <FormattedMessage id="kind" />
        </CustomFormLabelRadio>
        <RadioGroup onChange={handleChange}>
          <CustomStack direction={'row'} justifyContent={'space-between'}>
            <Radio value={DIFFICULTY_LEVEL.EASY}>
              <FormattedMessage id="easy" />
            </Radio>
            <Radio value={DIFFICULTY_LEVEL.MEDIUM}>
              <FormattedMessage id="medium" />
            </Radio>
            <Radio value={DIFFICULTY_LEVEL.DIFFICULT}>
              <FormattedMessage id="hard" />
            </Radio>
          </CustomStack>
        </RadioGroup>
      </FormControl>
    </FormContainer>
  );
};

export default DetailsForm;
