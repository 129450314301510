import axios from 'axios';
import { axiosCMSInstance, callAxiosGet, callAxiosPost } from '../../../utils/axiosUtil';

export const GetTopics = async (subjectId: number) => {
  const request = {
    docIds: [],
    setFormation: 'all',
    subjectId: subjectId
  };
  const response = await axiosCMSInstance.post(`/cms/qbank/listTopics`, request);

  return response.data;
};

export const GetSubTopics = async (topicId: number, subjectId: number) => {
  const request = {
    docIds: [],
    setFormation: 'all',
    subjectId: subjectId,
    topicIds: [topicId]
  };
  const response = await axiosCMSInstance.post(`cms/qbank/listSubTopics`, request);

  return response.data;
};

export const createAssessment = async (data: any) => {
  const { data: response } = await axios.post(`${process.env.REACT_APP_BACKEND_API}/assessments/`, data);
  return response.data;
};

export const createRules = async (data: any) => {
  const { data: response } = await axios.post(`${process.env.REACT_APP_BACKEND_API}/rules/`, data);
  return response.data;
};

export const generateAssessment = async (data: any) => {
  const { data: response } = await callAxiosPost(
    `${process.env.REACT_APP_BACKEND_API}/questions/?type=MCQ&no_of_questions=${data?.no_of_questions}`,
    data
  );
  return response.data;
};

export const GetSubjects = async () => {
  const response = await axiosCMSInstance.post(`/cms/qbank/listSubjects`, {});

  return response.data;
};

export const generateAssessmentFromDB = async (data: any) => {
  const { data: response } = await callAxiosPost(`${process.env.REACT_APP_BACKEND_API}/questions/db`, data);
  return response.data;
};

export const GetCourse = async () => {
  const response = await callAxiosGet(`${process.env.REACT_APP_BACKEND_API}/courses/1/`);
  return response.data;
};
