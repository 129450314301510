import { approveQuestionsType, regenerateQuestionsType, updateAssessmentStatus, uploadToCmsType } from '../types';
import { callAxiosGet, callAxiosPatch, callAxiosPost } from '../../../utils/axiosUtil';

export const GetGeneratedQuestions = async (assessmentId: string, limit: number, page: number) => {
  const response = await callAxiosGet(
    `${process.env.REACT_APP_BACKEND_API}/questions/by-document?document_id=${assessmentId}&limit=${limit}&page=1`
  );

  return response.data;
};

export const regenerateQuestions = async ({ index, questionId, data }: regenerateQuestionsType) => {
  const { data: response } = await callAxiosPost(
    `${process.env.REACT_APP_BACKEND_API}/questions/regenerate/${questionId}`,
    data
  );
  const regenQues = {
    data: response.data,
    index: index
  };
  return regenQues;
};

export const approveQuestions = async ({ questionId, data }: approveQuestionsType) => {
  const { data: response } = await callAxiosPatch(
    `${process.env.REACT_APP_BACKEND_API}/questions/approve/${questionId}`,
    data
  );
  return response.data;
};

export const assessmentStatusChange = async ({ assessmentId, data }: updateAssessmentStatus) => {
  const { data: response } = await callAxiosPatch(
    `${process.env.REACT_APP_BACKEND_API}/assessments/${assessmentId}`,
    data
  );
  return response.data;
};

export const uploadToCms = async ({ docId, tenantKey }: uploadToCmsType) => {
  // const { data: response } = await axiosBackendInstance.get(`/upload-to-lms?document_id=${docId}`);
  const { data: response } = await callAxiosPost(`${process.env.REACT_APP_BACKEND_API}/upload-to-lms`, {
    doc_id: docId,
    tenant_key: tenantKey
  });

  return response.data;
};
