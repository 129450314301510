import styled from 'styled-components';
import Typewriter from 'typewriter-effect';

export type TypewriterComponentProps = {
  text: string;
  defaultFunction?: () => void;
  shouldType: boolean;
};

const CustomTypewriter = styled(Typewriter)`
  && {
  }
`;

const TypewriterComponent = ({ text, defaultFunction, shouldType }: TypewriterComponentProps) => {
  if (!shouldType) {
    return <span>{text}</span>;
  }
  return (
    <CustomTypewriter
      options={{
        delay: 15,
        loop: false
      }}
      onInit={(typewriter) => {
        typewriter
          .typeString(text)
          .callFunction(function (state) {
            state.elements.cursor.style.display = 'none';
          })
          .callFunction(defaultFunction ? () => defaultFunction() : () => {})
          .start();
      }}
    />
  );
};

export default TypewriterComponent;
