import { useQuery } from 'react-query';
import { GetSubTopics, GetTopics } from '../containers/GenerateAssessment/api';

export const useGetSubTopics = (topicId: number, subjectId: number) => {
  return useQuery(['sub_topics', topicId], () => GetSubTopics(topicId, subjectId), {
    enabled: false
  });
};

export const useGetTopics = (subjectId: number) => {
  return useQuery(['topics', subjectId], () => GetTopics(subjectId), {
    enabled: false
  });
};
