import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './auth';

export const ProtectedRoute = () => {
  const context = useAuth();

  if (!context?.auth_data) {
    console.log('No Auth Data');
    return <Navigate to="/login" />;
  }
  return <Outlet />;
};
