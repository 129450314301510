import { styled } from 'styled-components';
import { siteColors } from '../../themes/colors';
import { Input } from '@chakra-ui/react';
import Logo from '../../images/icons/logo.svg';
import ProfilePic from '../../images/icons/defaultProfile.svg';

import fonts from '../../themes/fonts';
import { useNavigate } from 'react-router-dom';

const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${siteColors.primary};
  height: 4rem;
  padding: 0px 28px;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
`;

const StyledImg = styled.img`
  width: 1.125rem;
  height: 1.48rem;
`;

const StyledInput = styled(Input)`
  && {
    width: 18.75rem;
    height: 1.82rem;
    background-color: ${siteColors.blue};
    border: none;
    padding: 0.5rem 0.75rem;
    border-radius: 4px;
    color: ${siteColors.grey};
    ${fonts.weights.fw400()};
    ${fonts.size.xsmall()};
    line-height: 13.06px;

    &::placeholder {
      ${fonts.weights.fw400()};
      ${fonts.size.xsmall()};
      line-height: 13.06px;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
`;

const LogoText = styled.p`
  ${fonts.size.small()};
  color: ${siteColors.nuetral};
  ${fonts.weights.medium()};
  line-height: 0.95rem;
`;

const ProfileImg = styled.img`
  border-radius: 100%;
`;

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <NavbarContainer>
      <LogoContainer onClick={() => navigate('/?next=true&page=1')}>
        <StyledImg src={Logo} />
        <LogoText>Assessment Generator</LogoText>
      </LogoContainer>

      <StyledInput placeholder="Search for an assessment" _placeholder={{ color: `${siteColors.grey}` }} />

      <ProfileImg src={ProfilePic} />
    </NavbarContainer>
  );
};

export default Navbar;
