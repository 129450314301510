import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { siteColors } from '../../../../themes/colors';
import fonts from '../../../../themes/fonts';
import DetailsForm from '../DetailsForm';
import { FormattedMessage } from 'react-intl';
import { Button, Alert, AlertIcon, CloseButton, useToast } from '@chakra-ui/react';
import { GenerateButtonProps, SidebarProps } from '../../types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import RuleInfo from '../RuleInfo';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { createAssessment, createRules, generateAssessment, generateAssessmentFromDB } from '../../api';
import { GetQuestions } from '../../../ViewAssessment/api';

const Container = styled.div`
  height: 100%;
  width: 25.62rem;
  background: ${siteColors.blue};

  display: flex;
  flex-direction: column;
  padding: 2.5rem;
  overflow-y: auto;
`;

const Divider = styled.div`
  height: 0px;
  border: 1px solid ${siteColors.tagTextGen};
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

const AddRulesButton = styled.div`
  cursor: pointer;
  background: none;
  display: flex;
  padding: 0.5rem 0.75rem;
  gap: 0.5rem;
  margin-left: auto;
  border-radius: 4px;

  &:hover {
    background: ${siteColors.primary};
  }
`;

const AddRulesText = styled.p`
  color: ${siteColors.nuetral};
  ${fonts.size.regular()};
  ${fonts.weights.fw600()};
  line-height: 1.08rem;
`;

const StyleImg = styled.img``;

const GenerateButton = styled(Button)<GenerateButtonProps>`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;

    border-radius: 4px;
    background: ${(props) => (props.isActive ? `${siteColors.green}` : `${siteColors.disabledButtonColor}`)};

    color: ${(props) => (props.isActive ? `${siteColors.nuetral}` : `${siteColors.disabledButtonTextColor}`)};
    ${fonts.size.regular()};
    ${fonts.weights.fw600()};
    line-height: 1.08rem;

    &:hover {
      background: ${(props) =>
        props.isActive ? `${siteColors.tagTextUploaded}` : `${siteColors.disabledButtonColor}`};
    }
  }
`;

const SavedRulesWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-around;
  margin-top: auto;
`;

const CustomNotification = styled.div`
  width: 26rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  gap: 8px;
  background: ${siteColors.notificationBg};
  box-shadow: 0px 1px 11px ${siteColors.notificationBoxShadow};
  border-radius: 8px;
`;

const NotificationText = styled.p`
  color: ${siteColors.tagTextUploaded};
  ${fonts.size.regular()};
  ${fonts.weights.fw600()};
  line-height: 17px;
`;

const CustomAlert = styled(Alert)`
  && {
    overflow: visible;
  }
`;

const Sidebar = ({
  topicOptions,
  subTopicOptions,
  subjectOptions,
  courseOptions,
  selectTopic,
  selectSubject,
  shouldgenerateFromDB
}: SidebarProps) => {
  const [addRule, setaddRule] = useState<boolean>(false);
  const [canGenerate, setcanGenerate] = useState<boolean>(false);
  const [rulesData, setRulesData] = useState<any[]>([]);
  const [ruleIndex, setRuleIndex] = useState<number>(0);
  const [assessmentID, setassessmentID] = useState<number>(0);
  const [assessment, setAssessment] = useState<any>(null);
  const [savedRulesIdList, setsavedRulesIdList] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [quesNumLeft, setQuesNumLeft] = useState(6);
  const [delayedLoading, setDelayedLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const draftedId = searchParams.get('draftId');
  const alertRef = useRef<HTMLDivElement>(null);

  const [detailsFormData, setDetailsFormData] = useState({
    subjectName: '',
    totalQues: '',
    topic: '', // Add this
    subTopic: '', // Add this
    kind: ''
  });

  const queryClient = useQueryClient();

  const { data, refetch } = useQuery('draftedAssessments', () => GetQuestions(assessmentID, 0, 1), {
    enabled: false
  });

  useEffect(() => {
    return () => {
      queryClient.removeQueries('draftedAssessments');
    };
  }, []);

  useEffect(() => {
    if (draftedId) {
      setassessmentID(Number(draftedId));
    }
  }, [draftedId]);

  useEffect(() => {
    if (draftedId && assessmentID) {
      refetch();
    }
  }, [draftedId, assessmentID]);

  // useEffect(() => {
  //   if (data) {
  //     setaddRule(false);

  // setRulesData(
  //   () =>
  //     data?.data?.rules?.map((rule: any, index: number) => ({
  //       topic: rule?.topic_name,
  //       subTopic: rule?.subtopic_name,
  //       totalQues: rule?.num_of_questions,
  //       kind: rule?.difficult_level,
  //       index: index + 1
  //     }))
  // );
  //     setRulesData(() =>
  //       data?.data?.rules?.map((rule: any, index: number) => ({
  //         topic: rule?.topic_name,
  //         subTopic: rule?.subtopic_name,
  //         totalQues: rule?.num_of_questions,
  //         kind: rule?.difficult_level,
  //         index: index + 1,
  //       }))
  //     );

  // const dForm = {
  //   totalQues: 0
  // };
  // let qLeft = 0;
  // if we get the count from the API that means the assessment has already been generated.
  // const cumulativeRuleSum = data?.data?.rules?.reduce((total: number, currRule: any) => {
  //   return total + currRule?.num_of_questions;
  // }, 0);
  // if (data?.data?.assessment?.count) {
  //   setDetailsFormData({
  //     ...detailsFormData,
  //     totalQues: data?.data.assessment?.count
  //   });
  //   dForm.totalQues = data?.data.assessment?.count;
  //   qLeft = dForm.totalQues - cumulativeRuleSum;
  //   setQuesNumLeft(qLeft);
  // } else {
  //   qLeft = cumulativeRuleSum + 2;
  //   setQuesNumLeft(qLeft);
  //   dForm.totalQues = qLeft;
  // }
  //     const dForm = {
  //       totalQues: 0,
  //     };
  //     let qLeft = 0;
  //     // if we get the count from the API that means the assessment has already been generated.
  //     const cumulativeRuleSum = data?.data?.rules?.reduce(
  //       (total: number, currRule: any) => {
  //         return total + currRule?.num_of_questions;
  //       },
  //       0
  //     );
  //     if (data?.data?.assessment?.count) {
  //       setDetailsFormData({
  //         ...detailsFormData,
  //         totalQues: data?.data.assessment?.count,
  //       });
  //       dForm.totalQues = data?.data.assessment?.count;
  //       qLeft = dForm.totalQues - cumulativeRuleSum;
  //       setQuesNumLeft(qLeft);
  //     } else {
  //       qLeft = cumulativeRuleSum + 2;
  //       setQuesNumLeft(qLeft);
  //       dForm.totalQues = qLeft;
  //     }

  // setDetailsFormData(() => ({
  //   ...dForm,
  //   subjectName: data?.data?.assessment?.subject_id,
  //   courseName: data?.data?.assessment?.course_name
  // }));

  // setRuleIndex(data?.data?.rules?.length);
  // setsavedRulesIdList(() => data?.data?.rules?.map((rule: any) => rule?.rule_id));
  //     setRuleIndex(data?.data?.rules?.length);
  //     setsavedRulesIdList(() =>
  //       data?.data?.rules?.map((rule: any) => rule?.rule_id)
  //     );

  //     if (data?.data?.rules?.length > 0 && qLeft === 0) {
  //       setcanGenerate(true);
  //     }
  //   }
  // }, [data]);

  const toast = useToast({ position: 'top-right' });

  const handleDetailsChange = (e: any) => {
    if (typeof e === 'string') {
      setDetailsFormData((prevFormData) => ({
        ...prevFormData,
        kind: e
      }));
      return;
    }

    const { name, value } = e.target;
    setDetailsFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
    setErrorMessage('');
    if (name === 'totalQues') {
      setQuesNumLeft(Number(value));
    }
    if (name === 'subjectName') {
      selectSubject(value);
    }
    if (name === 'topic') {
      const parts = value.split(',');
      const extractedValue = parts[0];
      selectTopic(extractedValue);
    }
  };

  const isFormEmpty = Object.values(detailsFormData).some((value) => value === '');

  const navigate = useNavigate();

  const onRuleSave = (ruleFormData: any, ruleFormSubmitData: any) => {
    if (quesNumLeft - Number(ruleFormData.totalQues) < 0) {
      setErrorMessage(`cannot add more than ${quesNumLeft} questions`);
    } else {
      const qLeft = quesNumLeft - Number(ruleFormData.totalQues);
      setQuesNumLeft(qLeft);
      if (qLeft === 0) {
        setcanGenerate(true);
      }

      setaddRule(false);
      setRulesData((prevData: any) => [...prevData, ruleFormData]);
      const submitData = {
        difficulty_level: ruleFormSubmitData.kind,
        assessment_id: assessmentID,
        topic_id: Number(ruleFormSubmitData.topic),
        subtopic_id: Number(ruleFormSubmitData.subTopic),
        num_of_questions: Number(ruleFormSubmitData.totalQues)
      };

      mutateRules.mutate(submitData);
    }
  };

  const mutateAssessment = useMutation(createAssessment, {
    onSuccess: (data) => {
      setassessmentID(data.id);
      setAssessment(data);
    },
    onError: () => {
      alert('there was an error');
    }
  });

  const mutateRules = useMutation(createRules, {
    onSuccess: (data) => {
      setsavedRulesIdList((prevData) => [...prevData, data.id]);
    },
    onError: () => {
      alert('there was an error');
    }
  });

  const mutateQuestions = useMutation(generateAssessment, {
    onSuccess: (data) => {
      // navigate(`/`);
    },
    onError: (error: any) => {
      // alert("there was an error");
    }
  });

  const mutateQuestionsFromDB = useMutation(generateAssessmentFromDB, {
    onSuccess: (data) => {
      setTimeout(() => {
        setDelayedLoading(false);
        navigate(`/review-assessment/${assessmentID}?generated=true`);
      }, 5000);
    },
    onError: (error: any) => {
      alert('No questions available');
    }
  });

  // const handleCreateAssessment = () => {
  //   if (!isFormEmpty) {
  //     setaddRule(true);
  //     setRuleIndex(1);
  //     if (assessmentID === 0) {
  //       const assessmentInput = {
  //         course_name: detailsFormData.courseName,
  //         //for demo
  //         course_id: 1,
  //         count: detailsFormData.totalQues
  //       };

  //       mutateAssessment.mutate(assessmentInput);
  //     }
  //   } else {
  //     setErrorMessage('Please fill in all the fields.');
  //   }
  // };

  const generateRulesInputList = (rulesList: any[]) => {
    return rulesList.map((item: any) => ({
      rule_id: item
    }));
  };

  const handleGenerateQuestions = () => {
    const generateQuestionsInput = {
      type: 'MCQ',
      topic_id: detailsFormData?.topic.split(',')[1],
      subtopic_id: detailsFormData?.subTopic,
      difficulty_level: detailsFormData?.kind,
      no_of_questions: detailsFormData?.totalQues
    };

    // const generateQuestionsInput = {
    //   type: 'MCQ',
    //   topic_id: 'limits',
    //   subtopic_id: 'Limit Laws',
    //   difficulty_level: detailsFormData?.kind,
    //   no_of_questions: detailsFormData?.totalQues
    // };

    mutateQuestions.mutate(generateQuestionsInput);
    setTimeout(() => {
      navigate(`/?next=true`);
      toast({
        duration: 5000,
        render: () => (
          <CustomNotification>
            <NotificationText>{`Document will be generated shortly`}</NotificationText>
          </CustomNotification>
        )
      });
    }, 1000);
  };

  useEffect(() => {
    if (errorMessage && alertRef.current) {
      alertRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [errorMessage]);

  const handleAlertClose = () => {
    setErrorMessage('');
  };

  const handleGenerateQuestionsFromDB = () => {
    const generateQuestionsInputDB = {
      assessment_id: assessmentID,
      rules: generateRulesInputList(savedRulesIdList)
    };
    setDelayedLoading(true);

    mutateQuestionsFromDB.mutate(generateQuestionsInputDB);
  };

  return (
    <Container>
      {errorMessage && (
        <div ref={alertRef}>
          <CustomAlert status="error" mb={4}>
            <AlertIcon />
            {errorMessage}
            <CloseButton ml="auto" onClick={handleAlertClose} position="relative" top="-4px" right="-4px" />
          </CustomAlert>
        </div>
      )}
      <DetailsForm
        status={assessment?.status || data?.data?.assessment?.assessment_status}
        subjectOptions={subjectOptions}
        courseOptions={courseOptions}
        topicOptions={topicOptions}
        subTopicOptions={subTopicOptions}
        handleChange={handleDetailsChange}
        formData={detailsFormData}
      />
      <Divider />
      {/* <If condition={ruleIndex === 0 && quesNumLeft !== 0}>
        <AddRulesButton onClick={handleCreateAssessment}>
          <StyleImg src={AddIcon} />
          <AddRulesText>
            <FormattedMessage id="create_rule" />
          </AddRulesText>
        </AddRulesButton>
      </If> */}
      <SavedRulesWrap>
        {rulesData?.map((ruleData: any, index: number) => <RuleInfo key={index} ruleData={ruleData} />)}
      </SavedRulesWrap>

      <ButtonContainer>
        <GenerateButton
          isDisabled={isFormEmpty}
          isActive={!isFormEmpty}
          onClick={handleGenerateQuestions}
          isLoading={mutateQuestions.isLoading}
          loadingText={'Generating'}
        >
          <FormattedMessage id="generate_assess" />
        </GenerateButton>
      </ButtonContainer>
    </Container>
  );
};

export default Sidebar;
