import styled from 'styled-components';
import { siteColors } from '../../../../themes/colors';
import fonts from '../../../../themes/fonts';
import { CorrectOptionProps, QuestionCardProps } from '../../types';

const Container = styled.div`
  width: 100%;
  // max-width: 59.38rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 17px ${siteColors.boxShadow};
  box-sizing: border-box;
`;

const QuestionText = styled.p`
  color: ${siteColors.black};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  line-height: 17px;
`;

const StyledList = styled.ul`
  margin-top: 2rem;
  list-style: none;
  counter-reset: list-item;
`;

const StyledListItem = styled.li<CorrectOptionProps>`
  position: relative;
  margin-bottom: 1rem;
  color: ${(props) => (props.isAnswer ? `${siteColors.tagTextUploaded}` : `${siteColors.black}`)};
  ${fonts.size.regular()};
  ${(props) => (props.isAnswer ? ` ${fonts.weights.fw600()}` : `${fonts.weights.fw400()}`)};

  line-height: 17px;
  padding-left: 1.25rem;
  counter-increment: list-item;

  &::before {
    content: counter(list-item, upper-alpha);

    position: absolute;
    left: 0;
    top: 0;
    color: ${(props) => (props.isAnswer ? `${siteColors.tagTextUploaded}` : `${siteColors.black}`)};
    ${fonts.size.regular()};
    ${fonts.weights.fw600()};
    line-height: 17px;
  }
`;

const QuestionCard = ({ count, ques }: QuestionCardProps) => {
  return (
    <Container>
      <QuestionText>{`${count + 1}. ${ques.question}`}</QuestionText>
      <StyledList>
        {Object.entries(JSON.parse(ques?.choices)).map(([key, value]: any) => (
          <StyledListItem key={key} isAnswer={ques.answer === key}>
            {value}
          </StyledListItem>
        ))}
      </StyledList>
    </Container>
  );
};

export default QuestionCard;
