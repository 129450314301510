import styled from 'styled-components';
import { siteColors } from '../../../../themes/colors';
import UpThinkLogo from '../../../../images/upThinkLogo.svg';
import fonts from '../../../../themes/fonts';
import { Button, Spinner } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { GetStartedViewProps } from '../../types';
import If from '../../../../components/If';
import LiveIcon from '../../../../images/icons/liveIcon.svg';

const Container = styled.div`
  height: 100vh;
  width: 100%;
  background: ${siteColors.blue};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledImg = styled.img`
  height: 100px;
  margin-bottom: 1.5rem;
`;

const WelcomeText = styled.h2`
  color: ${siteColors.nuetral};
  font-size: 4rem;
  ${fonts.weights.fw400()};
  line-height: 4rem;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const StyledButton = styled(Button)`
  && {
    background: ${siteColors.green};
    color: ${siteColors.nuetral};
    font-size: 2rem;
    ${fonts.weights.fw600()};
    line-height: 3.8rem;
    padding: 1.5rem 2.5rem;
    border-radius: 4px;

    &:hover {
      background: ${siteColors.tagTextUploaded};
    }
  }
`;

const ProgressTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  gap: 8px;
  background: rgba(9, 74, 130, 0.3);
  border-radius: 100px;

  position: absolute;
  top: 4.3%;
  right: 4.3%;
`;

const IconImg = styled.img`
  height: 32px;
  width: 32px;
`;

const ProgressText = styled.p`
  font-size: 32px;
  ${fonts.weights.fw400()};
  line-height: 3rem;
  color: ${siteColors.whiteLMS};
`;

const GetStartedView = ({ showDashboard, isLoading }: GetStartedViewProps) => {
  return (
    <Container>
      <ProgressTag>
        <IconImg src={LiveIcon} />
        <ProgressText>Product demo in progress</ProgressText>
      </ProgressTag>
      <ContentContainer>
        <StyledImg src={UpThinkLogo} />
        <WelcomeText>
          <FormattedMessage id="welcome" />
        </WelcomeText>
        <If condition={!isLoading} otherwise={<Spinner color="blue.500" />}>
          <StyledButton onClick={showDashboard}>
            <FormattedMessage id="get_started" />
          </StyledButton>
        </If>
      </ContentContainer>
    </Container>
  );
};

export default GetStartedView;
