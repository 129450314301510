import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CommonNavbar from '../../components/CommonNavbar';
import { siteColors } from '../../themes/colors';
import fonts from '../../themes/fonts';
import Sidebar from './components/Sidebar';
import EmptyPageIcon from '../../images/emptyFillPage.svg';
import { useQuery } from 'react-query';
import { GetCourse, GetSubjects } from './api';
import { useGetSubTopics, useGetTopics } from '../../hooks';
import { Switch } from '@chakra-ui/react';

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  overflow-y: hidden;
`;

const SubContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
`;

const FillDetails = styled.p`
  color: ${siteColors.black};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  line-height: 1.0625;
`;

const StyledImg = styled.img``;

const GenerateAssessment = () => {
  const [shouldgenerateFromDB, setShouldGenerateFromDB] = useState<boolean>(true);
  const [topicSelected, setTopicSelected] = useState<number>(0);
  const [subjectSelected, setSubjectSelected] = useState<number>(0);

  const topicQuery = useGetTopics(subjectSelected);
  const subTopicQuery = useGetSubTopics(topicSelected, subjectSelected);

  const subjectQuery = useQuery('subjects', () => GetSubjects());

  const courseQuery = useQuery('courses', () => GetCourse());

  const [subjectsOptions, setSubjectsOptions] = useState<any[]>([]);
  const [courseOptions, setCourseOptions] = useState<any[]>([]);
  const [topicOptions, settopicOptions] = useState<any[]>([]);
  const [subTopicOptions, setsubTopicOptions] = useState<any[]>([]);

  const handleTopicSelected = (topicId: number) => {
    setTopicSelected(topicId);
  };

  const handleSubjectSelected = (subjectId: number) => {
    setSubjectSelected(subjectId);
  };

  useEffect(() => {
    if (topicSelected) {
      subTopicQuery.refetch();
      setsubTopicOptions([]);
    }
  }, [topicSelected]);

  useEffect(() => {
    if (subjectSelected) {
      topicQuery.refetch();
      settopicOptions([]);
    }
  }, [subjectSelected]);

  useEffect(() => {
    topicQuery.data?.topics?.forEach((topic: any) => {
      settopicOptions((prevData) => [...prevData, { value: topic.id, label: topic.name }]);
    });
  }, [topicQuery?.data?.topics]);

  useEffect(() => {
    subjectQuery.data?.subjects?.forEach((subject: any) => {
      setSubjectsOptions((prevData) => [...prevData, { value: subject?.id, label: subject?.name }]);
    });
  }, [subjectQuery?.data?.subjects]);

  useEffect(() => {
    courseQuery.data?.data?.forEach((course: any) => {
      setCourseOptions((prevData) => [...prevData, { value: course?.id, label: course?.name }]);
    });
  }, [courseQuery?.data?.data]);

  useEffect(() => {
    subTopicQuery.data?.subTopics?.forEach((subTopic: any) => {
      setsubTopicOptions((prevData) => [...prevData, { value: subTopic.id, label: subTopic?.name }]);
    });
  }, [subTopicQuery?.data?.subTopics]);

  return (
    <Container>
      <CommonNavbar />
      <ContentContainer>
        <Sidebar
          subjectOptions={subjectsOptions}
          topicOptions={topicOptions}
          subTopicOptions={subTopicOptions}
          courseOptions={courseOptions}
          selectTopic={handleTopicSelected}
          shouldgenerateFromDB={shouldgenerateFromDB}
          selectSubject={handleSubjectSelected}
        />

        <Switch
          style={{ position: 'absolute', right: 0, margin: 10 }}
          id="my-switch"
          isChecked={shouldgenerateFromDB}
          onChange={() => {
            setShouldGenerateFromDB((prevChecked) => !prevChecked);
          }}
          colorScheme="blue"
          size="lg"
        />
        <SubContentContainer>
          <StyledImg src={EmptyPageIcon} />
        </SubContentContainer>
      </ContentContainer>
    </Container>
  );
};

export default GenerateAssessment;
