import { createContext, useContext, useMemo, useState } from 'react';
import { IAuthContext } from './types';
// import { Navigate, redirect } from "react-router-dom";

const AuthContext = createContext<IAuthContext | null>(null);

const AuthProvider = ({ children }: any) => {
  const authDataString = window.localStorage.getItem('smsw-auth-data');
  const authData: any = JSON.parse(authDataString!);
  const userProfileString = window.localStorage.getItem('smsw-user');
  const userProfile: any = JSON.parse(userProfileString!);
  const userEmail = userProfile?.email;
  const [token, setToken] = useState(window.localStorage.getItem('smsw-bearer-token'));

  const setJwtToken = (jwtToken: string) => {
    setToken(jwtToken);
  };

  const context: IAuthContext = useMemo(
    () => ({
      authenticated: !!token,
      auth_data: authData,
      email: userEmail,
      user_profile: userProfile
    }),
    [token, userEmail, authData, userProfile]
  );

  if (token) {
    console.log('Token exists');
  } else {
    console.log('Token does not exist');
    // return <Navigate to="/login" />;
  }
  console.log('Props: >>>', children);

  // Provide the authentication context to the children components
  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
