import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { siteColors } from '../../../../themes/colors';
import fonts from '../../../../themes/fonts';
import { CardStyleProps, CorrectOptionProps, QuestionCardProps, TypingAnimationProps } from '../../types';

import approveIcon from '../../../../images/icons/correctIcon.svg';
import recycleIcon from '../../../../images/icons/recycleIcon.svg';
import approvedIcon from '../../../../images/approvedIcon.svg';
import If from '../../../../components/If';
import TypewriterComponent from '../../../../components/TypewriterComponent';

const Container = styled.div<CardStyleProps>`
  width: 100%;
  display: flex;
  ${(props) => props.isGenerated && `min-height: 14rem;`}
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 17px ${siteColors.boxShadow};
`;

const QuestionText = styled.p<TypingAnimationProps>`
  color: ${siteColors.black};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  line-height: 17px;
`;

const StyledList = styled.ul`
  margin-top: 2rem;
  list-style: none;
  counter-reset: list-item;
`;

const StyledListItem = styled.li<CorrectOptionProps>`
  position: relative;
  margin-bottom: 1rem;
  color: ${(props) => (props.isAnswer ? `${siteColors.tagTextUploaded}` : `${siteColors.black}`)};
  ${fonts.size.regular()};
  ${(props) => (props.isAnswer ? ` ${fonts.weights.fw600()}` : `${fonts.weights.fw400()}`)};

  line-height: 17px;

  counter-increment: list-item;

  &::first-letter {
    ${fonts.weights.fw600()};
    padding-right: 0.3rem;
  }
`;

const SubContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

const StyledImg = styled.img`
  cursor: pointer;
`;

const StyledRecycleImg = styled.img`
  cursor: pointer;
  width: 56px;
  height: 57px;
`;

const ApprovedImg = styled.img``;

const QuestionCard = ({ count, quesData, shouldType, handleapprove, handleQuesRegenration }: QuestionCardProps) => {
  const [approve, setApprove] = useState(false);
  const [canRenderOptions, setcanRenderOptions] = useState(false);
  const [renderedOptions, setRenderedOptions] = useState<any[]>([]);

  useEffect(() => {
    let timeoutId: any;

    if (quesData && canRenderOptions && shouldType) {
      const optionsData = JSON.parse(quesData?.choices);
      const keys = Object.keys(optionsData);

      const delay = 500; // Set the delay between rendering each component (in milliseconds)

      keys.forEach((key, index) => {
        timeoutId = setTimeout(() => {
          setRenderedOptions((prevRenderedItems: any) => [...prevRenderedItems, key]);
        }, delay * index);
      });
    }

    if (!shouldType) {
      const optionsData = JSON.parse(quesData?.choices);
      const keys = Object.keys(optionsData);
      setRenderedOptions(keys);
    }

    return () => clearTimeout(timeoutId);
  }, [quesData, canRenderOptions, shouldType]);

  return (
    <Container isGenerated={shouldType}>
      <QuestionText>
        <TypewriterComponent
          text={`${count + 1}. ${quesData.question}`}
          defaultFunction={() => setcanRenderOptions(true)}
          shouldType={shouldType}
        />
      </QuestionText>

      <SubContainer>
        <StyledList>
          {Object.entries(JSON.parse(quesData?.choices)).map(([key, value]: any) => (
            <StyledListItem key={key} isAnswer={quesData.answer === key}>
              {renderedOptions.includes(key) && (
                <TypewriterComponent text={`${key} ${value}`} shouldType={shouldType}></TypewriterComponent>
              )}
            </StyledListItem>
          ))}
        </StyledList>
        <If condition={quesData.status !== 'approved'} otherwise={<ApprovedImg src={approvedIcon} />}>
          <ActionsContainer>
            <StyledImg
              src={approveIcon}
              onClick={() => {
                handleapprove(quesData, count);
              }}
            />
            <StyledRecycleImg src={recycleIcon} onClick={() => handleQuesRegenration(quesData, count)} />
          </ActionsContainer>
        </If>
      </SubContainer>
    </Container>
  );
};

export default QuestionCard;
