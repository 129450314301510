import styled from 'styled-components';
import { siteColors } from '../../../../themes/colors';
import fonts from '../../../../themes/fonts';
import { SidebarProps } from '../../types';
import RuleInfo from '../RuleInfo';

const SideBarContainer = styled.div`
  height: 100%;
  width: 25.62rem;
  min-width: 25.62rem;
  background: ${siteColors.blue};

  display: flex;
  flex-direction: column;
  padding: 2.5rem 2.5rem 0px;
  overflow-y: auto;
`;

const Heading = styled.p`
  color: ${siteColors.nuetral};
  ${fonts.size.xBig()};
  ${fonts.weights.fw400()};
  line-height: 1.63rem;
`;

const AssessmentInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;

  &:first-of-type {
    margin-top: 1.5rem;
  }
`;

const SubHeading = styled.p`
  color: ${siteColors.inActiveColor};
  ${fonts.size.small()};
  ${fonts.weights.fw400()};
  line-height: 15.23px;
`;

const InfoText = styled.p`
  color: ${siteColors.nuetral};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  line-height: 1rem;
`;

const Divider = styled.div`
  height: 0px;
  border: 1px solid ${siteColors.tagTextGen};
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

const SideBar = ({ totalQues, rulesData, assessmentData }: SidebarProps) => {
  return (
    <SideBarContainer>
      <Heading>Assessment Details</Heading>
      <AssessmentInfo>
        <SubHeading>Subject Name</SubHeading>
        <InfoText>Mathematics</InfoText>
      </AssessmentInfo>
      <AssessmentInfo>
        <SubHeading>Total Number of Questions</SubHeading>
        <InfoText>{totalQues}</InfoText>
      </AssessmentInfo>
      <AssessmentInfo>
        <SubHeading>Course Name</SubHeading>
        <InfoText>{assessmentData?.course_name}</InfoText>
      </AssessmentInfo>
      <AssessmentInfo>
        <SubHeading>Kind</SubHeading>
        <InfoText>Fixed</InfoText>
      </AssessmentInfo>

      <Divider />
      {rulesData?.map((ruleData: any, index: number) => (
        <RuleInfo key={ruleData.id} ruleData={ruleData} index={index} />
      ))}
    </SideBarContainer>
  );
};

export default SideBar;
