import axios from 'axios';

export const axiosBackendInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API
});

export const axiosCMSInstance = axios.create({
  baseURL: process.env.REACT_APP_CMS_API_URL
});

const setAuthHeaderCMS = (config: any) => {
  let token = '';
  const stringifiedAuth = localStorage.getItem('smsw-auth-data');
  if (stringifiedAuth) {
    token = JSON.parse(stringifiedAuth)?.id_token;
  }
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

const setAuthHeader = (config: any) => {
  let token = '';
  const stringifiedAuth = localStorage.getItem('smsw-auth-data');
  if (stringifiedAuth) {
    token = JSON.parse(stringifiedAuth)?.id_token;
  }
  if (token) {
    config.headers['token'] = token;
  }
  return config;
};

export const callAxiosPost = async (url: string, data: any) => {
  let token = '';
  const stringifiedAuth = localStorage.getItem('smsw-auth-data');
  if (stringifiedAuth) {
    token = JSON.parse(stringifiedAuth)?.id_token;
  }
  const response = await axios.post(url, data, {
    headers: {
      app: 'assessment-generator',
      token: token
    }
  });
  return response;
};

export const callAxiosGet = async (url: string) => {
  const response = await axios.get(url, {
    headers: {
      app: 'assessment-generator'
    }
  });
  return response;
};

export const callAxiosPatch = async (url: string, data: any) => {
  const response = await axios.patch(url, data, {
    headers: {
      app: 'assessment-generator'
    }
  });
  return response;
};

axiosBackendInstance.interceptors.request.use(setAuthHeader, (error) => Promise.reject(error));
axiosCMSInstance.interceptors.request.use(setAuthHeaderCMS, (error) => Promise.reject(error));
