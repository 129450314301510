import styled from 'styled-components';
import { siteColors } from '../../../../themes/colors';
import fonts from '../../../../themes/fonts';
import { StyledTabProps, TabProps } from '../../types';

const SubjectTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const SubjectText = styled.p<StyledTabProps>`
  color: ${(props) => (props.isActive ? `${siteColors.nuetral}` : `${siteColors.inActiveColor}`)};
  ${fonts.size.regular()};
  ${fonts.weights.fw600()};
  line-height: 1.06rem;
`;

const StatusBar = styled.div<StyledTabProps>`
  width: 100%;
  height: 0px;
  border: 4px solid ${siteColors.green};
  border: ${(props) => (props.isActive ? `4px solid ${siteColors.green}` : 'none')};
`;

const SubjectTab = ({ name, isActive }: TabProps) => {
  return (
    <SubjectTabContainer>
      <SubjectText isActive={isActive}>{name}</SubjectText>
      <StatusBar isActive={isActive} />
    </SubjectTabContainer>
  );
};

export default SubjectTab;
