import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { siteColors } from '../../../../themes/colors';
import fonts from '../../../../themes/fonts';
import { RuleInfoProps } from '../../types';

const Container = styled.div`
  background: ${siteColors.darkBlue};
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  margin-bottom: 1rem;
`;

const RuleHeading = styled.p`
  color: ${siteColors.inActiveColor};
  ${fonts.size.regular()};
  ${fonts.weights.fw600()};
  line-height: 17px;
`;

const RuleInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const SubHeading = styled.p`
  color: ${siteColors.inActiveColor};
  ${fonts.size.small()};
  ${fonts.weights.fw400()};
  line-height: 15.23px;
`;

const InfoText = styled.p`
  color: ${siteColors.nuetral};
  ${fonts.size.regular()};
  ${fonts.weights.fw400()};
  line-height: 1rem;
`;

const RuleInfo = ({ ruleData }: RuleInfoProps) => {
  return (
    <Container>
      <RuleHeading>{`Rule #${ruleData.index}`}</RuleHeading>
      <RuleInfoContainer>
        <SubHeading>
          <FormattedMessage id="topic" />
        </SubHeading>
        <InfoText>{ruleData?.topic}</InfoText>
      </RuleInfoContainer>
      <RuleInfoContainer>
        <SubHeading>
          <FormattedMessage id="sub_topic" />
        </SubHeading>
        <InfoText>{ruleData?.subTopic}</InfoText>
      </RuleInfoContainer>
      <RuleInfoContainer>
        <SubHeading>
          <FormattedMessage id="no_of_ques" />
        </SubHeading>
        <InfoText>{ruleData?.totalQues}</InfoText>
      </RuleInfoContainer>
      <RuleInfoContainer>
        <SubHeading>
          <FormattedMessage id="kind" />
        </SubHeading>
        <InfoText>{ruleData?.kind}</InfoText>
      </RuleInfoContainer>
    </Container>
  );
};

export default RuleInfo;
