import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from './auth';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { TokenRequest } from './types';

export const AuthPage = () => {
  const context = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [authenticated, setAuthenticated] = useState(false);

  const code = searchParams.get('code');

  useEffect(() => {
    if (!context?.auth_data) {
      console.log('No Auth Data in Auth');
      setAuthenticated(false);
    }
  }, [context]);

  useEffect(() => {
    if (code) {
      console.log('Code Exist ***** redirect_uri: ', process.env.REACT_APP_auth_redirect_uri);

      // healthCheck
      const infoUrl = `${process.env.REACT_APP_CMS_API_URL}/info`;
      const response = axios.post(infoUrl, {}, {});
      console.log('INFO RESPONSE: ', response);

      // get token and user profile details
      const tokenRequest: TokenRequest = {
        grant_type: 'authorization_code',
        client_id: `${process.env.REACT_APP_auth_client_id}`,
        code: code,
        redirect_uri: `${process.env.REACT_APP_auth_redirect_uri}`
      };
      const tokenUrl = `${process.env.REACT_APP_auth_tokenUrl}`;
      axios
        .post(tokenUrl, tokenRequest, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
        .then((res: any) => {
          console.log('Received response >>> ', res);
          if (res.status === 200 || res.statusText === 'OK') {
            console.log('Response of Token is OK');
            localStorage.setItem('smsw-auth-data', JSON.stringify(res.data));

            const profileRequest: any = {
              headers: {
                Authorization: 'Bearer ' + res.data.id_token
              }
            };
            const profileUrl = `${process.env.REACT_APP_CMS_API_URL}/auth/profile`;
            return axios.post(profileUrl, {}, profileRequest);
          }
        })
        .then((res: any) => {
          if (res.statusText === 'OK' || res.status === 200) {
            console.log('Response of Profile is OK');

            // Store token in the local storage.
            localStorage.setItem('smsw-bearer-token', res?.config?.headers?.Authorization);
            localStorage.setItem('smsw-user', JSON.stringify(res?.data));
          }
        })
        .catch((e) => {
          console.log('ERROR >>> ', e);
        });
      setAuthenticated(true);
    }
  }, [code]);

  useEffect(() => {
    if (authenticated) {
      console.log('Authenticated *****');
      navigate('/', { replace: true });
      console.log('Navigated to Home *****');
    }
  }, [authenticated, navigate, context, code]);

  return <></>;
};
