import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from "react-query-devtools";

const queryClient = new QueryClient({});

const messages = {
  en: require('./translations/en.json')
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <IntlProvider locale="en" messages={messages['en']}>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider>
        {/* <BrowserRouter> */}
        <App />
        {/* </BrowserRouter> */}
      </ChakraProvider>
      {/* <ReactQueryDevtools initialIsOpen={true} /> */}
    </QueryClientProvider>
  </IntlProvider>
);
