import { css } from 'styled-components';

// sizes

const regular = () => css`
  font-size: 1rem;
`;

const xRegular = () => css`
  font-size: 1.125rem;
`;
const small = () => css`
  font-size: 0.875rem;
`;
const big = () => css`
  font-size: 1.25rem;
`;
const xBig = () => css`
  font-size: 1.5rem;
`;
const large = () => css`
  font-size: 2rem;
`;
const extraLarge = () => css`
  font-size: 3rem;
`;

const extraSmall = () => css`
  font-size: 0.5rem;
`;
const xsmall = () => css`
  font-size: 0.75rem;
`;

const xlarge = () => css`
  font-size: 2.625rem;
`;

const bannerLarge = () => css`
  font-size: 5.59rem;
`;

const size15px = () => css`
  font-size: 0.93rem;
`;

const superLarge = () => css`
  font-size: 7.5rem;
`;

// Font-Family
const Inter = () => css`
  font-family: 'Inter';
`;

const SpoofFamily = () => css`
  font-family: 'Spoof-Bold';
`;

const SpoofRegular = () => css`
  font-family: 'Spoof-Regular';
`;

const SpotwriterFamily = () => css`
  font-family: 'Spotwriter-Headline';
`;

const SpotwriterMono = () => css`
fontfamily:'Spotwriter-Mono`;

// weights
const light = () => css`
  font-weight: light;
`;
const bold = () => css`
  font-weight: bold;
`;

const normal = () => css`
  font-weight: normal;
`;

const medium = () => css`
  font-weight: 500;
`;

const fw400 = () => css`
  font-weight: 400;
`;

const fw700 = () => css`
  font-weight: 700;
`;

const fw600 = () => css`
  font-weight: 600;
`;
// styles
const heading = () => css`
  ${large()}
  ${bold()}
`;

const subheading = () => css`
  ${big()}
  ${bold()}
`;

const smallBoldText = () => css`
  ${small()}
  ${bold()}
`;

const standard = () => css`
  ${regular()}
  ${normal()}
`;

const subText = () => css`
  ${small()}
  ${normal()}
`;

const smallText = () => css`
  ${xsmall()}
  ${fw400()}
`;

const spoofHeading = () => css`
  ${SpoofFamily()}
  ${extraLarge()}
`;

const checkBoxText = () => css`
  ${xsmall()}
  ${fw400()}
`;

// eslint-disable-next-line
export default {
  size: {
    regular,
    small,
    big,
    xBig,
    large,
    extraLarge,
    xRegular,
    extraSmall,
    xsmall,
    xlarge,
    bannerLarge,
    size15px,
    superLarge
  },
  style: {
    heading,
    subheading,
    standard,
    subText,
    smallBoldText,
    spoofHeading,
    smallText,
    checkBoxText
  },
  weights: {
    light,
    bold,
    normal,
    medium,
    fw400,
    fw600,
    fw700
  },
  family: {
    SpoofFamily,
    SpotwriterFamily,
    SpoofRegular,
    SpotwriterMono,
    Inter
  }
};
