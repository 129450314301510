import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import GenerateAssessment from '../containers/GenerateAssessment';
import HomeContainer from '../containers/HomeContainer';
import { LoginPage } from './Login';
import ViewAssessment from '../containers/ViewAssessment';
import ReviewAssessment from '../containers/ReviewAssessment';
import { AuthPage } from '.';
import { ProtectedRoute } from './protectedroute';

const Routes = () => {
  const publicRoutes: any = [
    {
      path: '/',
      element: <HomeContainer />
    },
    {
      path: '/login',
      element: <LoginPage />
    },
    {
      path: '/auth',
      element: <AuthPage />
    }
  ];

  const authenticatedRoutes: any = [
    {
      path: '/',
      element: <ProtectedRoute />,
      exact: true,
      children: [
        {
          path: '/generate-assessment',
          element: <GenerateAssessment />,
          exact: true
        },
        {
          path: '/view-assessment/:assessmentId',
          element: <ViewAssessment />
        },
        {
          path: '/review-assessment/:assessmentId/:numQues',
          element: <ReviewAssessment />
        }
      ]
    }
  ];

  // Combine and conditionally include routes based on authentication status
  const router = createBrowserRouter([...publicRoutes, ...authenticatedRoutes]);

  // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default Routes;
