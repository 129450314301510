import { Button, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import Navbar from '../../components/Navbar';
import { siteColors } from '../../themes/colors';
import fonts from '../../themes/fonts';
import AddIcon from '../../images/icons/addIcon.svg';
import SettingsIcon from '../../images/icons/settings.svg';
import SubjectTab from './components/SubjectTab';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import If from '../../components/If';
import GetStartedView from './components/GetStartedView';
import { useQuery } from 'react-query';
import { GetAssessmentsData } from './api';
import Pagination from '../../components/Pagination';
import { useAuth } from '../../auth/auth';
import StatusTags from './components/StatusTags';

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${siteColors.blue};
  padding: 40px 40px 0px;
  gap: 1.69rem;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const UserName = styled.h1`
  ${fonts.size.large()};
  ${fonts.weights.medium()};
  line-height: 2.17rem;
  color: ${siteColors.nuetral};
  letter-spacing: -1%;
`;

const StyledButton = styled(Button)`
  && {
    background: ${siteColors.green};
    color: ${siteColors.nuetral};
    ${fonts.size.regular()};
    ${fonts.weights.fw600()};
    line-height: 1.06rem;
    padding: 0.75rem 0.875rem;
    border-radius: 4px;

    &:hover {
      background: ${siteColors.tagTextUploaded};
    }
  }
`;

const SettingsButton = styled(Button)`
  && {
    background: ${siteColors.primary};
    color: ${siteColors.nuetral};
  }
`;

const StyledImg = styled.img``;

const SubjectsContainer = styled.div`
  display: flex;
  gap: 40px;
`;

const DataContainer = styled.div`
  padding: 2.5rem;
`;

const CustomTableContainer = styled(TableContainer)`
  && {
    border: 1px solid ${siteColors.lightBlue};
    border-radius: 1.25rem;
  }
`;

const CustomTableHead = styled(Thead)`
  && {
    background: ${siteColors.lighterBlue};
  }
`;

const CustomTh = styled(Th)`
  && {
    color: ${siteColors.textGrey};
    ${fonts.size.small()};
    ${fonts.weights.fw700()};
    line-height: 0.94rem;
    text-transform: none;
    letter-spacing: 0px;
  }
`;
const CustomTd = styled(Td)`
  && {
    border-bottom: 1px solid ${siteColors.lightBlue};
    color: ${siteColors.black};
    ${fonts.size.regular()};
    ${fonts.weights.fw400()};
    line-height: 1.08rem;
    text-transform: none;
  }
`;

const TableRow = styled(Tr)`
  && {
    cursor: pointer;
    &:hover {
      background: ${siteColors.lighterBlue};
    }
  }
`;

const HomeContainer = () => {
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const [paginatedData, setPaginatedData] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [searchParams, setSearchParams] = useSearchParams();

  const context = useAuth();

  useEffect(() => {
    if (!context?.auth_data) {
      console.log('No Auth Data in HomeContainer');
    }
  }, [context]);

  const nextParam = searchParams.get('next');
  const pageParam = searchParams.get('page');

  const startIdx = (currentPage - 1) * rowsPerPage;
  const endIdx = Number(startIdx + rowsPerPage);

  const { data, isLoading, isError, refetch } = useQuery('assessments', () => GetAssessmentsData(), {
    enabled: nextParam === 'true'
  });

  // const totalPages = Math.ceil(data?.data?.doc_ids_data?.length / rowsPerPage);
  useEffect(() => {
    if (pageParam) {
      setCurrentPage(Number(pageParam));
    }
  }, [pageParam]);

  useEffect(() => {
    if (data) {
      setPaginatedData(data.data?.doc_ids_data?.slice(startIdx, endIdx));
      setTotalCount(data.data?.doc_ids_data?.length);
    }
  }, [data, startIdx, endIdx]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    setSearchParams({ next: 'true', page: `${pageNumber}` });
  };

  useEffect(() => {
    if (isLoading) {
      setShowSpinner(true);
      // const timeoutId = setTimeout(() => {
      //   setShowSpinner(false);
      // }, 1000);

      // return () => clearTimeout(timeoutId);
    } else {
      const timeoutId = setTimeout(() => {
        setShowSpinner(false);
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [isLoading]);

  const handleGetStarted = () => {
    // FetchUserDataByCode();
    refetch();
    setTimeout(() => {
      setSearchParams({ page: `${currentPage}`, next: 'true' });
    }, 1000);
  };

  // const handleRowsChange = (e: any) => {
  //   setRowsPerPage((e) => setInformation(e.target.value))
  // };

  // const handleRowClick = (assessment: any) => {
  //   if (assessment.assessment_status === ASSESSMENT_STATUS.DRAFT && assessment.total_questions === 0) {
  //     navigate(`/generate-assessment?draftId=${assessment.assessment_id}`);
  //   } else if (assessment.assessment_status === ASSESSMENT_STATUS.GENERATED) {
  //     navigate(`/review-assessment/${assessment.assessment_id}`);
  //   } else {
  //     navigate(`/view-assessment/${assessment.assessment_id}`);
  //   }
  // };

  const handleRowClick = (assessment: any) => {
    navigate(`/review-assessment/${assessment.doc_id}/${assessment.num_questions}`);
  };

  const handleRowChange = (e: any) => {
    const newRowNumber = Number(e.target.value);
    setRowsPerPage(newRowNumber);
  };

  return (
    <If
      condition={(!(isLoading || isError || showSpinner) && data) || nextParam}
      otherwise={<GetStartedView showDashboard={handleGetStarted} isLoading={showSpinner} />}
    >
      <Container>
        <Navbar />
        <ContentContainer>
          <HeaderContainer>
            <InfoContainer>
              <UserName>Welcome User!</UserName>
              <ActionContainer>
                <StyledButton
                  leftIcon={<StyledImg src={AddIcon} />}
                  variant="solid"
                  onClick={() => navigate('/generate-assessment')}
                >
                  <FormattedMessage id="generate_assessment" />
                </StyledButton>
                <SettingsButton leftIcon={<StyledImg src={SettingsIcon} />} variant="solid" />
              </ActionContainer>
            </InfoContainer>
            <SubjectsContainer>
              <SubjectTab name={'Mathematics'} isActive={true} />
              <SubjectTab name={'Science'} isActive={false} />
            </SubjectsContainer>
          </HeaderContainer>
          <DataContainer>
            <CustomTableContainer>
              <Table>
                <CustomTableHead>
                  <Tr>
                    <CustomTh>
                      <FormattedMessage id="assessment_id" />
                    </CustomTh>
                    {/* <CustomTh>
                      <FormattedMessage id="course_name" />
                    </CustomTh> */}
                    <CustomTh>
                      <FormattedMessage id="total_ques" />
                    </CustomTh>
                    {/* <CustomTh>
                      <FormattedMessage id="status" />
                    </CustomTh> */}
                    <CustomTh>
                      <FormattedMessage id="status" />
                    </CustomTh>
                    <CustomTh>
                      <FormattedMessage id="created_on" />
                    </CustomTh>
                  </Tr>
                </CustomTableHead>
                <Tbody>
                  {data &&
                    paginatedData?.map((assessment: any) => (
                      <TableRow
                        onClick={() => {
                          if (assessment.assessment_status !== 'Failed') {
                            handleRowClick(assessment);
                          }
                        }}
                        key={assessment.doc_id}
                      >
                        <CustomTd>{assessment.doc_id}</CustomTd>
                        {/* <CustomTd>{assessment?.course_name} </CustomTd> */}
                        <CustomTd>{assessment.num_questions}</CustomTd>
                        <CustomTd>
                          {
                            <StatusTags
                              status={`${
                                assessment.status.charAt(0).toUpperCase() + assessment.status.toLowerCase().slice(1)
                              }`}
                            />
                          }
                        </CustomTd>
                        <CustomTd>
                          {new Date(assessment.latest_created_at).toLocaleDateString('en-US', {
                            month: 'short',
                            day: '2-digit',
                            year: 'numeric'
                          })}
                        </CustomTd>
                      </TableRow>
                    ))}
                </Tbody>
              </Table>
            </CustomTableContainer>
            <Pagination
              currentPage={currentPage}
              onPageChange={handlePageChange}
              startIdx={startIdx}
              endIdx={endIdx}
              totalCount={totalCount}
              handleRowChange={handleRowChange}
              rowValue={rowsPerPage}
            />
          </DataContainer>
        </ContentContainer>
      </Container>
    </If>
  );
};

export default HomeContainer;
