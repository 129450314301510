export const siteColors = {
  primary: '#041F36',
  nuetral: '#F9F9F9',
  blue: '#03182A',
  grey: '#AFAFAF',
  green: '#579829',
  inActiveColor: '#7597B4',
  lightBlue: '#CFDDEB',
  lighterBlue: '#EDF0F3',
  textGrey: '#4A4A4A',
  black: '#1E1F22',
  tagTextGen: '#094A82',
  tagBgGen: '#094A820D',
  tagBorderGen: '#094A821A',
  tagTextDrafts: '#AFAFAF',
  tagBgDrafts: '#AFAFAF1A',
  tagBorderDrafts: '#AFAFAF4D',
  tagTextUploaded: '#6FB43F',
  tagBgUploaded: '#6FB43F0D',
  tagBorderUploaded: '#6FB43F33',
  darkBlue: '#031021',
  boxShadow: 'rgba(226, 226, 237, 0.8)',
  white: '#DEDEDE',
  inputBorderColor: '#07355D',
  disabledButtonTextColor: '#384D5F',
  disabledButtonColor: '#213749',
  actionBoxShadow: 'rgba(119, 119, 119, 0.25)',
  whiteLMS: '#FFFFFF',
  LMSBg: '#F1F1F9',
  lightGreen: '#DDEDD1',
  notificationBg: '#F8FBF5',
  notificationBoxShadow: 'rgba(0, 0, 0, 0.13)'
};
